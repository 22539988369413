import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ensureFamiliaCristaMembersQueryData, useFamiliaCristaMembersQuery } from "data/queries/queryFamiliaCristaMembers";
import { ensureFamiliaCristaQueryData, useFamiliaCristaQuery } from "data/queries/queryFamiliaCrista";
import { invalidateFamiliaCristaReportsQuery } from "data/queries/queryFamiliaCristaReports";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { NumericFormat } from 'react-number-format';
import * as React from 'react';
import fetch from "data/fetch";

const Status = {
  PRESENTE: "PRESENTE",
  AUSENTE: "AUSENTE",
  TRABALHO: "TRABALHO",
  ENFERMIDADE: "ENFERMIDADE",
  VIAGEM: "VIAGEM",
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputElementRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

export async function loader({ params: { id } }) {
  return await Promise.all([
    ensureFamiliaCristaQueryData(id),
    ensureFamiliaCristaMembersQueryData(id),
  ]);
}

export async function action() {
  // TODO move form submit to action
}

export default function FamiliaCristaReportNewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = useFamiliaCristaQuery({ id });
  const caringGroup = queryData?.data;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reportDate, setReportDate] = useState(moment());
  const [numeroConvidados, setNumeroConvidados] = useState("");
  const [estudoId, setEstudoId] = useState("");
  const [oferta, setOferta] = useState("");
  const [dizimo, setDizimo] = useState("");
  const [ofertaEspecial, setOfertaEspecial] = useState("");
  const [ofertaMissao, setOfertaMissao] = useState("");
  const [hinos, setHinos] = useState("");
  const [curas, setCuras] = useState("");
  const [milagres, setMilagres] = useState("");
  const [conversoes, setConversoes] = useState("");
  const [mensagem, setMensagem] = useState("");
  const { data: memberQueryData } = useFamiliaCristaMembersQuery({ id });
  const members = memberQueryData?.data;
  const [formData, setFormData] = useState({});

  const onMemberDataChange = (newMemberData) => {
    setFormData({
      ...formData,
      [newMemberData.relationId]: newMemberData,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setIsSubmitting(true);
    const dataReuniao = moment(reportDate).format("YYYY-MM-DD");

    try {
      await fetch(`/v1/familia-crista-relatorio`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          familiaCristaId: id,
          dataReuniao,
          numeroConvidados: parseInt(numeroConvidados, 10),
          estudoId,
          hinos,
          curas,
          milagres,
          conversoes,
          mensagem,
          relatorioRelations: Object.entries(formData).map(([relationId, row]) => ({
            familiaCristaRelationId: relationId,
            situacao: row.status,
            liderEmFormacao: row.liderEmFormacao === "Yes",
            comentario: row.comment,
            oferta,
            dizimo,
            ofertaEspecial,
            ofertaMissao,
          })),
        }),
      });

      invalidateFamiliaCristaReportsQuery(id);
      navigate(`/familia-crista/${id}`);
    } catch (e) {
      setErrorMessage(
        e.apiMessage ?? "Sorry, an unexpected error has occurred."
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm" component="form" method="post" onSubmit={onSubmit}>
      <Breadcrumbs
        heading={t("Christian Family")}
        links={[
          {
            name: t("Christian Family"),
            to: "/familia-crista/list",
          },
          {
            name: caringGroup?.lider?.name ?? "...",
            to: `/familia-crista/${id}`,
          },
          {
            name: t("New Report"),
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Stack spacing={4}>
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            <ReportDateField value={reportDate} onChange={setReportDate} />
            <TextField
              label={t("Number of Guests")}
              type="number"
              value={numeroConvidados}
              onChange={(e) => setNumeroConvidados(e.target.value)}
              required
            />
            <TextField
              label={t("Study ID")}
              type="text"
              value={estudoId}
              onChange={(e) => setEstudoId(e.target.value)}
              required
            />
            <TextField
              label={t("Offering")}
              value={oferta}
              onChange={(e) => setOferta(e.target.value)}
              name="oferta"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              required
            />
            <TextField
              label={t("Tithe")}
              value={dizimo}
              onChange={(e) => setDizimo(e.target.value)}
              name="dizimo"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              required
            />
            <TextField
              label={t("Special Offering")}
              value={ofertaEspecial}
              onChange={(e) => setOfertaEspecial(e.target.value)}
              name="ofertaEspecial"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              required
            />
            <TextField
              label={t("Mission Offering")}
              value={ofertaMissao}
              onChange={(e) => setOfertaMissao(e.target.value)}
              name="ofertaMissao"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              required
            />
            <TextField
              label={t("Songs")}
              type="text"
              value={hinos}
              onChange={(e) => setHinos(e.target.value)}
              required
            />
            <TextField
              label={t("Healings")}
              type="text"
              value={curas}
              onChange={(e) => setCuras(e.target.value)}
              required
            />
            <TextField
              label={t("Miracles")}
              type="text"
              value={milagres}
              onChange={(e) => setMilagres(e.target.value)}
              required
            />
            <TextField
              label={t("Conversions")}
              type="text"
              value={conversoes}
              onChange={(e) => setConversoes(e.target.value)}
              required
            />
            <TextField
              label={t("Message")}
              type="text"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              required
            />
            {members?.map((m) => (
              <MemberRow
                key={m.id}
                name={m.name}
                memberData={
                  formData[m.relationId] ?? {
                    relationId: m.relationId,
                  }
                }
                onMemberDataChange={onMemberDataChange}
              />
            ))}
          </Stack>
        </Card>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="success"
            size="large"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? t("Submitting...") : t("Submit")}
          </Button>
        </Stack>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Stack>
    </Container>
  );
}

function ReportDateField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={true}
      label={t("Report date") + " *"}
      value={value}
      onChange={onChange}
      disableFuture={true}
    />
  );
}

function MemberRow({ name, memberData, onMemberDataChange }) {
  const onStatusChange = (status) => {
    onMemberDataChange({
      ...memberData,
      status,
    });
  };

  const onCommentChange = (comment) => {
    onMemberDataChange({
      ...memberData,
      comment,
    });
  };

  const onLiderEmFormacaoChange = (liderEmFormacao) => {
    onMemberDataChange({
      ...memberData,
      liderEmFormacao,
    });
  };

  return (
    <Stack spacing={2}>
      <Typography noWrap>{name}</Typography>
      <StatusField value={memberData.status} onChange={onStatusChange} />
      <TextField
        label="Líder em Formação"
        select
        value={memberData.liderEmFormacao ?? "No"}
        onChange={(e) => onLiderEmFormacaoChange(e.target.value)}
        required
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </TextField>
      <CommentField value={memberData.comment} onChange={onCommentChange} />
    </Stack>
  );
}

function StatusField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <FormControl required={true}>
      <InputLabel>{t("Status")}</InputLabel>
      <Select value={value} onChange={changeHandler(onChange)}>
        <MenuItem value={Status.PRESENTE}>{t("Present")}</MenuItem>
        <MenuItem value={Status.AUSENTE}>{t("Absent")}</MenuItem>
        <MenuItem value={Status.TRABALHO}>{t("Work")}</MenuItem>
        <MenuItem value={Status.ENFERMIDADE}>{t("Sick")}</MenuItem>
        <MenuItem value={Status.VIAGEM}>{t("Travel")}</MenuItem>
      </Select>
    </FormControl>
  );
}

function CommentField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      label={t("Comment")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function changeHandler(setValue) {
  return (e) => {
    setValue(e.target.value);
  };
}
